import { tag } from "@fscrypto/domain";
import { DateTime } from "luxon";

export function mergeSearchParams(params: URLSearchParams, newParams: Record<string, string | number | boolean>) {
  params = new URLSearchParams(params.toString());
  for (const [key, value] of Object.entries(newParams)) {
    params.set(key, value.toString());
  }
  return "?" + params.toString();
}

export function optimizeCloudinaryImage(url: string | null | undefined, width: number) {
  if (url?.includes("cloudinary")) {
    return url.replace("upload/", `upload/w_${width},f_auto/`);
  }
  return url;
}

export function getMetaTagProjects(): string {
  return "Ethereum, Bitcoin, Solana, Avalanche, Near, Sei, Base, Axelar, Flow";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getObjectSizeAsString(obj: any) {
  let str = null;
  if (typeof obj === "string") {
    // If obj is a string, then use it
    str = obj;
  } else {
    // Else, make obj into a string
    str = JSON.stringify(obj);
  }
  // Get the length of the Uint8Array
  const bytes = new TextEncoder().encode(str).length;

  if (bytes < 1024) return bytes + "B";
  else if (bytes < 1048576) return (bytes / 1024).toFixed(0) + "KB";
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(0) + "MB";
  else return (bytes / 1073741824).toFixed(0) + "GB";
}

//TODO: handle the strings that are not dates at domain/zod level
export function getDateTimeDurationFromStrings(start: string, end: string) {
  const e = DateTime.fromISO(end);
  const s = DateTime.fromISO(start);
  return e.diff(s).shiftTo("seconds").toFormat("s's");
}

// TODO: remove the check for invalid duration after we switch over to compass
export function getDateTimeDurationFromDates(start: Date, end: Date): string {
  const e = DateTime.fromJSDate(end);
  const s = DateTime.fromJSDate(start);
  const duration = e.diff(s, "seconds");
  const durationStr = duration.toFormat("s's'");
  if (durationStr === "Invalid Duration") {
    return getDateTimeDurationFromStrings(`${start}`, `${end}`);
  }
  return durationStr;
}

export function isValidURL(data: string) {
  try {
    return Boolean(new URL(data));
  } catch (e) {
    return false;
  }
}

export function formatDateTimeAgo(date: Date): string {
  const diff = DateTime.fromJSDate(date).diffNow();

  // If the difference is less than 60 seconds, display the number of seconds ago
  if (Math.abs(diff.as("seconds")) < 60) {
    return formatDatePartsAgo("second", diff.as("seconds"));
  }

  // If the difference is less than 60 minutes, display the number of minutes ago
  if (Math.abs(diff.as("minutes")) < 60) {
    return formatDatePartsAgo("minute", diff.as("minutes"));
  }

  // If the difference is less than 24 hours, display the number of hours ago
  if (Math.abs(diff.as("hours")) < 24) {
    return formatDatePartsAgo("hour", diff.as("hours"));
  }

  // If the difference is less than 10 days, display the number of days ago
  if (Math.abs(diff.as("days")) < 10) {
    return formatDatePartsAgo("day", diff.as("days"));
  }

  return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
}

type DatePart = "second" | "minute" | "hour" | "day";

function formatDatePartsAgo(datePart: DatePart, value: number) {
  const cleanValue = Math.round(Math.abs(value));
  return `${cleanValue} ${datePart}${cleanValue > 1 ? "s" : ""} ago`;
}

export function emailIsValid(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export const truncateAddress = (address: string, options?: { left?: number; right?: number; maxLength?: number }) => {
  if (options?.maxLength && address.length <= options.maxLength) {
    return address;
  }
  const left = options?.left ?? 6;
  const right = options?.right ?? 4;
  if (address.length < left + right) {
    return address;
  }
  return `${address.slice(0, left)}...${address.slice(right * -1)}`;
};

export function progressTime(start: Date, end: Date): number {
  const now = new Date();
  if (end.getTime() < now.getTime()) {
    return 100;
  }

  const denominator = end.getTime() - start.getTime();
  const numerator = now.getTime() - start.getTime();
  return Math.min(100, (numerator / denominator) * 100);
}

export function formatDateCountDown(date: Date): string {
  const diff = DateTime.fromJSDate(date).diffNow();

  // If the date is in the past, display time ago
  if (diff.as("seconds") < 0) {
    return formatDateTimeAgo(date);
  }

  return diff.toFormat("d'd' h'h' m'm' s's");
}

export function roundRewardsAmount(amt: number): number {
  return Number(amt.toFixed(2));
}

export function formatUrlFromString(input: string | undefined | null): string {
  if (!input) return "";
  // Regular expression to validate the basic structure of a URL
  const urlPattern = /^(https?:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

  // Check if the input string matches the URL pattern
  if (urlPattern.test(input)) {
    // Check if the input already starts with http:// or https://, otherwise prepend https://
    if (!/^https?:\/\//i.test(input)) {
      return `https://${input}`;
    }
    return input; // Return the input as is if it already has http/https
  }

  // Return an empty string if the input is not a valid URL
  return "";
}

export function formatBlockchainProjectsForMeta(projects: tag.Tag[]): string {
  if (projects.length === 0) return "";

  let returnVal = "Featuring analysis of ";

  if (projects.length > 2) {
    const p = projects
      .slice()
      .sort((a, b) => {
        // Handle undefined sortOrder by setting them to a high value
        const orderA = a.sortOrder !== null ? a.sortOrder : Number.MAX_SAFE_INTEGER;
        const orderB = b.sortOrder !== null ? b.sortOrder : Number.MAX_SAFE_INTEGER;
        return orderA - orderB;
      })
      .map((p) => p.displayName as string)
      .slice(0, 5)
      .join(", ");
    returnVal += `${p}, and more`;
  } else if (projects.length === 2) {
    returnVal += projects.map((p) => p.displayName).join(" and ");
  } else if (projects.length === 1) {
    returnVal += projects[0].displayName;
  }

  returnVal += ".";

  return returnVal;
}
